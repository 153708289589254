<template>
    <el-main>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="form" label-width="130px">
            <el-form-item label="专区名称：" prop="title">
                <el-input :disabled="is_info" v-model="ruleForm.title" style="margin-right: 20px" maxlength="15"
                    placeholder="显示为活动标题"></el-input>
                <span style="color: #999999; font-size: 12px">名称不超过15个字，中文</span>
            </el-form-item>
            <el-form-item label="活动时间：" prop="title">
                <el-date-picker :disabled="is_info" v-model="ruleForm.time" type="datetimerange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
            </el-form-item>
            <el-form-item label="用户资格：" prop="user_level_id">
                <el-select :disabled="is_info" v-model="ruleForm.user_level_id" placeholder="请选择">
                    <el-option v-for="item in levelList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="限购设置：" prop="purchasing">
                <el-radio-group :disabled="is_info" v-model="ruleForm.purchasing">
                    <el-radio :label="1">不限购</el-radio>
                    <el-radio :label="2" style="line-height: 40px">
                        限购
                        <span style="margin: 0 15px">最多可购买的SKU</span>
                        <el-input :disabled="is_info" v-model="ruleForm.purchasing_num" type="Number" style="width: 200px">
                            <template slot="append">个</template>
                        </el-input>
                    </el-radio>
                </el-radio-group>
                <div class="tips" style="line-height: 12px; margin-top: 10px">
                    添加多个商品或多SKU情况，客户若将所有活动商品添加至购物车再付款，可享受多商品/多SKU是活动价。</div>
            </el-form-item>
            <el-form-item label="选择活动商品：" prop="goods">
                <el-button :disabled="is_info" @click="startChooseGoods">+ 选择商品</el-button>
                <el-table style="margin-top: 20px; width: 100%" :data="ruleForm.goods" :row-class-name="setRowClass"
                    default-expand-all>
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column type="expand">
                        <template v-slot="{ row, $index }">
                            <div class="spec_box" v-for="(item, index) in row.goods_spec" :key="index">
                                <div class="goodsInfo">
                                    <el-image style="width: 40px; height: 40px" :src="item.picture"></el-image>
                                    <div class="goodsName">{{ item.title }}</div>
                                </div>
                                <div class="price">￥{{ item.price }}</div>
                                <div class="matching_price">￥{{ item.new_comer_price }}</div>
                                <div class="matching_price">{{ item.flashStock }}</div>
                                <div class="caozuo" v-if="!is_info" @click="deleteGoods($index, index, item)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品信息" align="center">
                        <template v-slot="{ row }">
                            <div class="goodsInfo">
                                <el-image style="width: 60px; height: 60px" :src="row.goods_picture"></el-image>
                                <div class="goodsName">{{ row.goods_name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="原价" align="center">
                        <template v-slot="{ row }">￥{{ row.goods_price }}</template>
                    </el-table-column>
                    <el-table-column label="优惠价格" align="center">
                        <template v-slot="{ row }">
                            <div v-if="!row.is_spec">￥{{ row.new_comer_price }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠库存" align="center">
                        <template v-slot="{ row }">
                            <div v-if="!row.is_spec">{{ row.flashStock }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="{ $index }">
                            <!--  -->
                            <el-button type="text" v-if="!is_info" @click="deleteGoods($index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Paging :total="allChooseInfo.allChooseGoods.length" :page="allChooseInfo.allChoosePage"
                    :pageNum="allChooseInfo.allChooseRow" @updatePageNum="updateDataAll"></Paging>
            </el-form-item>
        </el-form>
        <!-- 选择商品弹框 -->
        <el-dialog title="选择商品" :visible.sync="chooseGoodsFlag" width="1000px">
            <div class="goodsList">
                <el-form class="el-form-search" label-width="90px">
                    <el-form-item label="搜索商品：">
                        <el-input style="width: 270px" v-model="goodsForm.goods_name" size="small"
                            placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="选择分类：">
                        <el-cascader size="small" clearable v-model="goodsForm.classify_id"
                            :options="goodsClass"></el-cascader>
                    </el-form-item>
                    <el-form-item label=" " label-width="20px">
                        <el-button @click="getGoodsList(1)" type="primary" size="small">搜索</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="goodsList" style="width: 100%" :row-class-name="setRowClass">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column type="expand">
                        <template v-slot="{ row }">
                            <div class="spec_box" v-for="(item, index) in row.goods_spec" :key="index">
                                <div class="goodsInfo">
                                    <el-image style="width: 40px; height: 40px" :src="item.picture"></el-image>
                                    <div class="goodsName">{{ item.title }}</div>
                                </div>
                                <div class="price">￥{{ item.price }}</div>
                                <div class="matching_price">
                                    <el-input v-model="item.new_comer_price" type="number" size="small"></el-input>
                                </div>
                                <div class="matching_price">
                                    <el-input v-model="item.flashStock" type="number" size="small"></el-input>
                                </div>
                                <div class="caozuo" @click="chooseThatGoods(row, item)">参与活动</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品信息" align="center">
                        <template v-slot="{ row }">
                            <div class="goodsInfo">
                                <el-image style="width: 60px; height: 60px" :src="row.goods_picture"></el-image>
                                <div class="goodsName">{{ row.goods_name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="原价" align="center">
                        <template v-slot="{ row }">￥{{ row.goods_price }}</template>
                    </el-table-column>
                    <el-table-column label="优惠价格" align="center">
                        <template v-slot="{ row }">
                            <el-input v-if="!row.is_spec" v-model="row.new_comer_price" type="number"
                                size="small"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠库存" align="center">
                        <template v-slot="{ row }">
                            <el-input v-if="!row.is_spec" v-model="row.flashStock" type="number" size="small"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="{ row }">
                            <el-button type="text" @click="chooseThatGoods(row)">参与活动</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Paging :total="total_number" :page="goodsForm.page" :pageNum="goodsForm.rows" @updatePageNum="updateData">
                </Paging>
            </div>
        </el-dialog>
        <Preservation @preservation="Preservation"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        Preservation,
        Paging,
    },
    data () {
        function checkGoods (that, callback) {
            if (!that.allChooseInfo.allChooseGoods.length) {
                callback(new Error('请选择活动商品'));
            } else {
                callback();
            }
        }
        let that = this;
        return {
            is_info: false,
            activity_id: null,
            ruleForm: {
                title: '',
                time: '',
                user_level_id: 0,
                purchasing: 1,
                purchasing_num: 1,
                goods: [],
            },
            // 所有选中的商品
            allChooseInfo: {
                allChoosePage: 1,
                allChooseRow: 5,
                allChooseGoods: [],
                allChooseTotal: 0,
            },
            rules: {
                title: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { max: 15, message: '长度在15个字符以内', trigger: 'blur' },
                ],
                time: [{ required: true, message: '请选择活动时间范围', trigger: 'change' }],
                purchasing: [{ required: true }],
                goods: [{ required: true, validator: (rule, value, callback) => checkGoods(that, callback), trigger: 'change' }],
            },
            chooseGoodsFlag: false,
            goodsForm: {
                page: 1,
                rows: 10,
                goods_name: '',
                classify_id: 0,
            },
            goodsList: [],
            total_number: 0,
            levelList: [],
            allGoodsHash: {},
        };
    },
    created () {
        this.$store.dispatch('goods/getTypeList');
        if (this.$route.query.id) {
            this.activity_id = this.$route.query.id;
            this.getInfo();
        }
        if (this.$route.path == '/extension/Newcomer/info') {
            this.is_info = true;
        }
        this.getLevelList();
    },
    computed: {
        goodsClass () {
            let goodsTypeList = _.cloneDeep(this.$store.getters.goodsTypeList);
            goodsTypeList.unshift({
                value: 0,
                label: '全部',
            });
            return goodsTypeList;
        },
    },
    methods: {
        getLevelList () {
            this.$axios.post(this.$api.user.CardLevel).then(res => {
                if (res.code === 0) {
                    res.result.list.unshift({ id: 0, title: '新用户' });
                    this.levelList = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 删除商品
        deleteGoods ($index, index, spec) {
            // 点击的商品在全部选中商品中的索引
            let allIndex = (this.allChooseInfo.allChoosePage - 1) * this.allChooseInfo.allChooseRow + $index;
            let obj = {};
            if (index === undefined) {
                obj.type = 'all';
                obj.id = this.allChooseInfo.allChooseGoods[allIndex].id;
            } else {
                obj.id = spec.id;
            }
            this.$confirm('此操作将删除该活动商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    if (!this.allChooseInfo.allChooseGoods[allIndex].actgoods_id && index === undefined) {
                        this.allChooseInfo.allChooseGoods.splice(allIndex, 1);
                        this.allChoosePage();
                        return;
                    }
                    if (spec && !spec.actgoods_id && index !== undefined) {
                        this.allChooseInfo.allChooseGoods[allIndex].goods_spec.splice(index, 1);
                        if (!this.allChooseInfo.allChooseGoods[allIndex].goods_spec.length) {
                            this.allChooseInfo.allChooseGoods.splice(allIndex, 1);
                        }
                        this.allChoosePage();
                        return;
                    }
                    this.$axios.post(this.$api.Newcomer.delActivityGoods, obj).then(res => {
                        if (res.code == 0) {
                            if (index === undefined) {
                                // 删除整个商品\
                                delete this.allGoodsHash[this.allChooseInfo.allChooseGoods[allIndex].goods_id];
                                this.allChooseInfo.allChooseGoods.splice(allIndex, 1);
                            } else {
                                // 删除单个商品的所有
                                delete this.allGoodsHash[this.allChooseInfo.allChooseGoods[allIndex].goods_id][spec.sku_id];
                                this.allChooseInfo.allChooseGoods[allIndex].goods_spec.splice(index, 1);
                                if (!this.allChooseInfo.allChooseGoods[allIndex].goods_spec.length) {
                                    delete this.allGoodsHash[this.allChooseInfo.allChooseGoods[allIndex].goods_id];
                                    this.allChooseInfo.allChooseGoods.splice(allIndex, 1);
                                }
                            }
                            this.$message.success('删除成功');
                            this.allChoosePage();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
            this.allChoosePage();
        },
        updateDataAll (val, status) {
            if (status == 0) {
                this.allChooseInfo.allChooseRow = val;
            } else {
                this.allChooseInfo.allChoosePage = val;
            }
            this.allChoosePage();
        },
        // 商品列表分页
        updateData (val, status) {
            if (status == 0) {
                this.goodsForm.rows = val;
            } else {
                this.goodsForm.page = val;
            }
            this.getGoodsList();
        },
        // 开始选购商品
        startChooseGoods () {
            this.goodsForm.page = 1;
            this.getGoodsList();
            this.chooseGoodsFlag = true;
        },
        // 弹框选中商品
        chooseThatGoods (data1, spec1) {
            let data = _.cloneDeep(data1);
            let spec = _.cloneDeep(spec1);
            let goods = -1;
            let is_edit = this.allGoodsHash[data.id];
            for (let i = 0; i < this.allChooseInfo.allChooseGoods.length; i++) {
                if (is_edit && this.allChooseInfo.allChooseGoods[i].goods_id == data.id) {
                    goods = i;
                    data.goods_id = data.id;
                    data.actgoods_id = this.allGoodsHash[data.id].actgoods_id;
                    if (data.is_spec) {
                        data.goods_spec.map(item => {
                            if (this.allGoodsHash[data.id][item.id]) {
                                item.actgoods_id = this.allGoodsHash[data.id][item.id].actgoods_id;
                            }
                        });
                    }
                    break;
                }
                if (!is_edit && this.allChooseInfo.allChooseGoods[i].id == data.id) {
                    goods = i;
                    break;
                }
            }
            // 点击添加一级商品
            if (!spec) {
                if (goods !== -1) {
                    this.allChooseInfo.allChooseGoods[goods] = data;
                    this.allChooseInfo.allChooseGoods[goods].goods_spec = data.goods_spec;
                    this.$message.success('修改成功');
                } else {
                    this.allChooseInfo.allChooseGoods.push(data);
                    this.$message.success('添加成功');
                }
            }
            // 点击添加规格商品
            if (spec) {
                if (goods !== -1) {
                    let isChoose = -1;
                    let spec_goods = this.allChooseInfo.allChooseGoods[goods].goods_spec;
                    for (let i = 0; i < spec_goods.length; i++) {
                        if (this.allChooseInfo.allChooseGoods[goods].actgoods_id && spec_goods[i].sku_id == spec.id) {
                            // 是修改
                            spec.actgoods_id = this.allGoodsHash[data.id][spec.id].id;
                            isChoose = i;
                            break;
                        }
                        if (this.allChooseInfo.allChooseGoods[goods].actgoods_id === undefined && spec_goods[i].id == spec.id) {
                            isChoose = i;
                            break;
                        }
                    }
                    if (isChoose !== -1) {
                        this.allChooseInfo.allChooseGoods[goods].goods_spec[isChoose] = spec;
                        this.$message.success('修改成功');
                    } else {
                        this.allChooseInfo.allChooseGoods[goods].goods_spec.push(spec);
                        this.$message.success('添加成功');
                    }
                } else {
                    data.goods_spec = [spec];
                    this.allChooseInfo.allChooseGoods.push(data);
                    this.$message.success('添加成功');
                }
            }
            this.allChoosePage();
        },
        // 是否有规格 处理不同类型的样式
        setRowClass ({ row, index }) {
            return row.is_spec ? '' : 'no_spec';
        },
        // 获取商品列表
        getGoodsList (style) {
            if (style) this.goodsForm.page = 1;
            let obj = {
                page: this.goodsForm.page,
                rows: this.goodsForm.rows,
            };
            if (this.goodsForm.goods_name) {
                obj.goods_name = this.goodsForm.goods_name;
            }
            if (this.goodsForm.classify_id) {
                obj.classify_id = this.goodsForm.classify_id;
            }
            this.$axios.post(this.$api.Newcomer.getGoodList, obj).then(res => {
                if (res.code === 0) {
                    this.goodsList = res.result.list;
                    this.total_number = res.result.total_number;
                    this.goodsList.map(item => {
                        if (item.is_spec) {
                            item.goods_spec.map(spec => {
                                this.$set(spec, 'new_comer_price', spec.price);
                                this.$set(spec, 'flashStock', spec.stock);
                            });
                        } else {
                            this.$set(item, 'new_comer_price', item.goods_price);
                            this.$set(item, 'flashStock', item.goods_stock);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 对选中的商品进行分页显示
        allChoosePage () {
            let arr = this.allChooseInfo.allChooseGoods.slice(
                (this.allChooseInfo.allChoosePage - 1) * this.allChooseInfo.allChooseRow,
                (this.allChooseInfo.allChoosePage - 1) * this.allChooseInfo.allChooseRow + this.allChooseInfo.allChooseRow
            );
            arr.map(item => {
                if (item.goods_picture === undefined) item.goods_picture = item.image;
                if (item.goods_name === undefined) item.goods_name = item.goods_title;
                if (item.goods_price === undefined) item.goods_price = item.price;
                if (item.flashStock === undefined) item.flashStock = item.stock;
                if (item.is_spec === undefined) {
                    item.is_spec = Boolean(item.goods_spec.length);
                    item.goods_spec.map(spec => {
                        // spec.id = spec.sku_id;
                        spec.picture = spec.image;
                        spec.title = spec.sku_title;
                        spec.flashStock = spec.stock;
                    });
                }
            });
            this.ruleForm.goods = arr;
        },
        // 保存活动配置
        Preservation () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.ruleForm.purchasing === 2 && this.ruleForm.purchasing_num <= 0) {
                        this.$message.warning('请填写正确的限购数量');
                        return;
                    }
                    let obj = _.cloneDeep(this.ruleForm);
                    if (obj.purchasing === 1) {
                        delete obj.purchasing_num;
                    }
                    delete obj.time;
                    obj.start_time = Math.floor(this.ruleForm.time[0] / 1000);
                    obj.end_time = Math.floor(this.ruleForm.time[1] / 1000);
                    obj.goods = [];
                    this.allChooseInfo.allChooseGoods.map(item => {
                        if (item.is_spec) {
                            // 多规格
                            item.goods_spec.map(spec => {
                                let specObj = {
                                    goods_id: item.id,
                                    sku_id: spec.sku_id || spec.id,
                                    new_comer_price: spec.new_comer_price,
                                    stock: spec.flashStock,
                                };
                                if (item.actgoods_id) {
                                    specObj.actgoods_id = spec.actgoods_id;
                                    specObj.goods_id = item.goods_id;
                                }
                                obj.goods.push(specObj);
                            });
                        } else {
                            // 没有规格
                            let goodsObj = {
                                goods_id: item.id,
                                new_comer_price: item.new_comer_price,
                                stock: item.flashStock,
                                sku_id: 0,
                            };
                            if (item.actgoods_id) goodsObj.actgoods_id = item.actgoods_id;
                            obj.goods.push(goodsObj);
                        }
                    });
                    if (this.activity_id) obj.id = this.activity_id;
                    let url = this.activity_id ? this.$api.Newcomer.edit : this.$api.Newcomer.add;
                    this.$axios.post(url, obj).then(res => {
                        if (res.code == 0) {
                            let msg = this.activity_id ? '编辑成功' : '添加成功';
                            this.$message.success(msg);
                            this.$router.push('/extension/Newcomer');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        // 编辑时获取活动详情
        getInfo () {
            this.$axios.post(this.$api.Newcomer.getInfo, { id: this.activity_id }).then(res => {
                if (res.code == 0) {
                    let info = res.result;
                    for (const key in this.ruleForm) {
                        if (key !== 'goods' && key !== 'time') this.ruleForm[key] = info[key];
                    }
                    this.ruleForm.time = [info.start_time * 1000, info.end_time * 1000];
                    info.goods.map(item => {
                        item.actgoods_id = item.id;
                        // 处理哈希树
                        this.allGoodsHash[item.goods_id] = _.cloneDeep(item);
                        if (this.allGoodsHash[item.goods_id].goods_spec.length > 0) {
                            // 有规格
                            this.allGoodsHash[item.goods_id].goods_spec.map((spec, index) => {
                                spec.actgoods_id = spec.id;
                                this.allGoodsHash[item.goods_id][spec.sku_id] = spec;
                                item.goods_spec[index].actgoods_id = spec.id;
                            });
                        }
                    });
                    this.allChooseInfo.allChooseGoods = info.goods;
                    this.allChoosePage();
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 80px;

    .el-form {

        .el-input,
        .el-select {
            width: 400px;
        }

        .tips {
            color: #a1a1a1;
            font-size: 12px;
        }

        .el-radio-group {
            display: flex;
            flex-direction: column;
        }

        .goodsList {

            .el-input,
            .el-select {
                width: 200px;
            }
        }
    }
}

.el-table {
    line-height: 23px;

    .el-input {
        width: 100px !important;
    }
}

.el-table {
    & /deep/ .no_spec+tr .el-table__expanded-cell {
        display: none;
    }

    & /deep/ .no_spec>td>.cell>.el-table__expand-icon {
        display: none;

        &>.el-icon {
            display: none;
        }
    }
}

.goodsInfo {
    display: flex;
    align-items: center;
    text-align: left;

    .el-image {
        flex-shrink: 0;
    }

    .goodsName {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-left: 15px;
    }

    .price {
        margin-left: 15px;
        color: #999;
    }
}

.spec_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 12px 30px;

    .goodsName {
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 18px;
    }

    &>div {
        padding: 0 10px;
    }

    .price,
    .caozuo,
    .matching_price {
        flex: 1;
        text-align: center;
    }

    .caozuo {
        color: #409eff;
        cursor: pointer;
    }
}

.el-table {
    width: 800px;
}</style>
